// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase:  {
    apiKey: "AIzaSyA4HfuKMp-SGJdnkztbDT9XkAQw_SXBfzo",
    authDomain: "oomap-3759a.firebaseapp.com",
    databaseURL: "https://oomap-3759a.firebaseio.com",
    projectId: "oomap-3759a",
    storageBucket: "oomap-3759a.appspot.com",
    messagingSenderId: "1078000434241",
    appId: "1:1078000434241:web:9afe57a79bb174804b1ac0",
    measurementId: "G-L0EK117F88"
  }
};
